<template>
    <van-popup v-model:show="this.$props.show"
               :style="{ height: '50%' }"
               position="bottom"
               :close-on-click-overlay="false"
               :lazy-render='true'
               teleport="body"
               closeable
               @click-close-icon="closeWin"
    >
        <van-form @submit="onConfirm">
            <van-cell-group title="扫描箱号" inset>
              <van-cell>
                <van-field
                        v-model="packageCode"
                        name="packageCode"
                        label="箱号"
                        placeholder="请扫描包装二维码"
                        required
                        :rules="[{ required: true, message: '请扫描包装二维码' }]"
                ></van-field>
              <van-button round block type="primary" @click="this.$emit('onclick', 'packageCode')" style="height: 5.33333vw">
                扫描二维码
              </van-button>

              </van-cell>

              <QRReader
                  v-model:show="show2"
                  @onRead="onRead"
              />


                <van-field
                        v-model="checkQty"
                        name="checkQty"
                        label="已核实数量"
                        placeholder="请输入已核实数量"
                        required
                        :rules="[{ required: true, message: '请输入已核实数量' }]"
                />
                <Picker
                    name="exceptType.code"
                    label="差异类型"
                    valueField="code"
                    placeholder="差异类型"
                    v-model:value="this.$data['exceptType.code']"
                    :defConfig="{
                   portName: 'system.foundation.Dic.getChildDicsByCode',
                      data: {code: 'RECEIPT_EXCEPT_TYPE'}
             }"
                    :rules="[{ required: true, message: '请选择装卸类型' }]"
                    required
                ></Picker>
                <div style="margin: 4.26667vw;">
                    <van-button round block type="primary" native-type="submit">
                        确定
                    </van-button>
                </div>
            </van-cell-group>
        </van-form>
    </van-popup>
</template>

<script>
    import _ from "lodash";
    import Picker from "../../components/Picker";
    import QRReader from "@/components/QRReader.vue";

    export default {
        name: "DiffDealWin",
        components: { Picker,QRReader},
        props: {
            show: {
                default: false,
                type: Boolean
            },
        },

        emits: ['update:show', 'onConfirm'],

        data() {
            return {
                show2:false,
                lastShow:"",
                packageCode: '',
                checkQty: '',
                "exceptType.code":""
            };
        },
        created() {

        },

        methods: {
          onRead(text) {
            var me=this;
            if(this.lastShow ==='packageCode') {
              me.packageCode = text;
              var option = {
                portName: 'fr.order.ReceiptOrderLevel.editStatus',
                data: {'packageCode': me.packageCode, 'orderCode': me.orderCode},
                needLoading: false,
                successCallback: function (data) {
                  me.packCodeList.push(text);
                  /*me.$refs.grid.$props.requestData['receiptOrder.id'] = me.receiptOrderID;
                  me.$refs.grid.onRefresh();*/
                }
              };
              this.$sapi.callPort(option);
            }else if(this.lastShow ==='diffPackCode') {
              me.$refs.scanWin.packageCode = text;
              var option1 = {
                portName: 'fr.order.ReceiptOrderLevel.editStatus',
                data: {'packageCode': text, 'orderCode': me.orderCode},
                needLoading: false,
                successCallback: function (data) {
                  let checkQty = data.data.checkQty;
                  me.$refs.scanWin.checkQty = checkQty;
                }
              };
              this.$sapi.callPort(option1);
            }
          },
            onConfirm(value, index) {
                if (_.isEmpty(value)) {
                    return;
                }
                this.packageCode = '';
                this.qty = '';
                this.$emit('update:show', false);
                this.$emit('onConfirm', value);
            },
            closeWin(value, index) {
                this.packageCode = '';
                this.qty = '';
                this.$emit('update:show', false);
            }
        }
    }
</script>

<style scoped>

</style>