<template>
    <div class="page-asset">
        <!-- 列表 -->
        <div>
            <van-list @load="initload">
                <van-cell v-for="item in list" :key="item.name" :title="item.name" @click="handleList(item)"/>
            </van-list>
        </div>

    </div>
</template>
<script>

import ListCard from "../../components/ListCard";
import GridList from "../../components/GridList";
import Picker from "../../components/Picker";
import DiffDealWin from "../receiveScan/DiffDealWin";
import QRReader from "@/components/QRReader.vue";

export default {
    name: "receiveScan",
    components: {},
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
        }
    },
    created() {
        this.getScanCode();
        this.initload()
    },
    methods: {
        handleList(item) {
            console.log(item)
            this.$router.push({
                path: 'receiveCheck', query: {
                    id: item.id,
                    name: item.name,
                    code: item.code
                }
            })
        },
        // 列表刷新
        initload() {
            // 异步更新数据
            // setTimeout 仅做示例，真实场景中一般为 ajax 请求
            let that = this
            var option1 = {
                portName: 'cms.Check.getZlList',
                // data: {'packageCode': text, 'orderCode': me.orderCode},
                // needLoading: false,
                successCallback: function (data) {
                    console.log(data, '资产盘点列表')
                    that.list = data.entities;

                    // 加载状态结束
                    // this.loading = false;

                    // 数据全部加载完成
                    // if (this.list.length >= data.totalcount) {
                    //     this.finished = true;
                    // }
                }
            };
            // this.list = a
            // console.log(this.list, 'list')
            this.$sapi.callPort(option1);


            // setTimeout(() => {
            //     for (let i = 0; i < 10; i++) {
            //         this.list.push(this.list.length + 1);
            //     }
            //     // 加载状态结束
            //     this.loading = false;

            //     // 数据全部加载完成
            //     if (this.list.length >= 80) {
            //         this.finished = true;
            //     }
            // }, 1000);
        },
        onfocus(value) {
            this.focusModel = 'diffPackCode';
        },
        onclick(value) {
            this.show = true
            this.lastShow = "diffPackCode"
        },

        onRead(text) {
            var me = this;
            if (this.lastShow === 'packageCode') {
                me.packageCode = text;
                var option = {
                    portName: 'fr.order.ReceiptOrderLevel.editStatus',
                    data: { 'packageCode': me.packageCode, 'orderCode': me.orderCode },
                    needLoading: false,
                    successCallback: function (data) {
                        me.packCodeList.push(text);
                        /*me.$refs.grid.$props.requestData['receiptOrder.id'] = me.receiptOrderID;
                        me.$refs.grid.onRefresh();*/
                    }
                };
                this.$sapi.callPort(option);
            } else if (this.lastShow === 'diffPackCode') {
                me.$refs.scanWin.packageCode = text;
                var option1 = {
                    portName: 'fr.order.ReceiptOrderLevel.editStatus',
                    data: { 'packageCode': text, 'orderCode': me.orderCode },
                    needLoading: false,
                    successCallback: function (data) {
                        let checkQty = data.data.checkQty;
                        me.$refs.scanWin.checkQty = checkQty;
                    }
                };
                this.$sapi.callPort(option1);
            }
        },
        getScanCode: function () {
            if (!window.Capacitor) {
                return;
            }
            var me = this;
            Capacitor.Plugins.Scanner.addListener('onScan', (data) => {
                var barcode = data.barcode;
                console.log(data.barcode);
                switch (me.focusModel) {
                    case 'packageCode':
                        me.packageCode = barcode;
                        var option = {
                            portName: 'fr.order.ReceiptOrderLevel.editStatus',
                            data: { 'packageCode': me.packageCode, 'orderCode': me.orderCode },
                            needLoading: false,
                            successCallback: function (data) {
                                me.packCodeList.push(barcode);
                                /*me.$refs.grid.$props.requestData['receiptOrder.id'] = me.receiptOrderID;
                                me.$refs.grid.onRefresh();*/
                            }
                        };
                        this.$sapi.callPort(option);
                        break;
                    case 'diffPackCode':
                        me.$refs.scanWin.packageCode = barcode;
                        var option1 = {
                            portName: 'fr.order.ReceiptOrderLevel.editStatus',
                            data: { 'packageCode': barcode, 'orderCode': me.orderCode },
                            needLoading: false,
                            successCallback: function (data) {
                                let checkQty = data.data.checkQty;
                                me.$refs.scanWin.checkQty = checkQty;
                            }
                        };
                        this.$sapi.callPort(option1);
                        break;
                }
            })
        },
        diffDeal(value) {
            this.showEdit = true;
        },
        scanCheck: function (id, materialID) {
            materialId.push({ path: 'receiveCheck', query: { materialId: materialID } })
        },
        bindPackCode: function (id, materialID) {
            this.$router.push({ path: 'bindPackageCode', query: { materialId: materialID } })
        },
        submitReceive(value) {
            var me = this;
            var option = {
                portName: 'fr.order.ReceiptOrderLevel.saveReceive',
                data: { 'receiptOrder.id': me.receiptOrderID, 'packCodeList': me.packCodeList },
                needLoading: true,
                successCallback: function (data) {
                    me.$dialog.alert({
                        title: '',
                        message: '提交成功',
                    }).then(() => {
                        me.packCodeList = [];
                        me.$refs.grid.$props.requestData['receiptOrder.id'] = me.receiptOrderID;
                        me.$refs.grid.onRefresh();
                    });
                }
            };
            this.$sapi.callPort(option);
        },
        onOrderCodeConfirm(value) {
            this.packCodeList = [];
            this.orderCode = value.code;
            this.receiptOrderID = value.id;
            this.$refs.grid.$props.requestData['receiptOrder.id'] = this.receiptOrderID;
            this.$refs.grid.onRefresh();
        },
        confirmEdit(value) {
            value['orderCode'] = this.orderCode;
            var option = {
                portName: 'fr.order.ReceiptOrderLevel.diffDeal',
                data: value,
                needLoading: true,
                successCallback: () => {
                    this.$dialog.alert({
                        title: '',
                        message: '上报成功',
                    }).then(() => {
                        this.$refs.grid.$props.requestData['receiptOrder.id'] = this.receiptOrderID;
                        this.$refs.grid.onRefresh();
                    });
                }
            };
            this.$sapi.callPort(option);
        },
    }
}

</script>

<style scoped>
.item {
    display: flex;
    border-radius: 5px;
    flex-direction: row;
    box-shadow: 0 0 2px #ddd;
    align-items: center;
    padding: 10px;
    margin: 2px;
}

.item+.item {
    margin-top: 10px;
}

.item>.content {
    flex: 1;
}

.item .time {
    color: #666;
}
</style>